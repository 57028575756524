import React from "react"
import "./App.css"
import { BrowserRoutes } from "./Middlewares/BrowserRoutes"
import { AuthProvider } from "./Middlewares/AuthContext"

function App() {
  return (
    <AuthProvider>
      <BrowserRoutes />
    </AuthProvider>
  )
}

export default App

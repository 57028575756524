import React from "react"
import { Route, Routes } from "react-router-dom"
import AppLayout from "../Layouts/AppLayout"
import AdminLayout from "../Layouts/AdminLayout"
import ProtectedRoutes from "./ProtectedRoutes"
import { useAuth } from "./AuthContext"

const COMPONENTS = {
  HomePage: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/HomePage/HomePage")),
  ),
  Login: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/LoginPage/Login")),
  ),
  Signup: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/SignUp/Signup")),
  ),
  ForgotPassword: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/ForgetPassword/forgetPassword"),
    ),
  ),
  ResetPassword: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/ResetPassword/ResetPassword"),
    ),
  ),
  NewOffre: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/AddOffer/newOffre")),
  ),
  EditOffer: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/EditOffer/EditOffer")),
  ),
  MyOffers: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/Offers/MyOffers")),
  ),
  UserPage: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/myBooking/MyBookings")),
  ),
  BookingRequest: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/BookingRequest/BookingRequest"),
    ),
  ),
  ProfileSetting: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/ProfileSettings/ProfileSetting"),
    ),
  ),
  Statistics: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/Statistics/Statistics")),
  ),
  ChangePassword: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/ChangePassword/ChangePassword"),
    ),
  ),
  NewCheckOut: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/CheckOut/NewCheckOut")),
  ),
  ProfilePage: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/ProfilePage/ProfilePage"),
    ),
  ),
  PaymentSuccess: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/Payment/PaymentSuccess")),
  ),
  PaymentFail: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/Payment/PaymentFail")),
  ),
  ContactUs: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/ContactUs/ContactUs")),
  ),
  PrivacyPolicy: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Containers/PrivacyPolicy/PrivacyPolicy"),
    ),
  ),
  CGV: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Components/CGV/CGV")),
  ),
  OfferDetails: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../Components/OfferDetails/OfferDetails"),
    ),
  ),
  AdminHome: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/home/Home")),
  ),
  AdminOffersList: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/list/OffersList")),
  ),
  AdminLogin: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/login/AdminLogin")),
  ),
  UsersPage: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/Users/UsersPage")),
  ),
  Bookings: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../AdminSpace/pages/Bookings/Bookings"),
    ),
  ),
  AdminPage: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../AdminSpace/pages/new-admin/NewAdmin"),
    ),
  ),
  AdminListPage: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../AdminSpace/pages/new-admin/AdminList"),
    ),
  ),
  MarketingPage: React.lazy(() =>
    awaitDelay(1000).then(
      () => import("../AdminSpace/pages/MarketingPage/MarketingPage"),
    ),
  ),
  ChangePasswordAdmin: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/ChangePassword")),
  ),
  Coupon: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/coupon")),
  ),
  CouponCreate: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/coupon/create")),
  ),
  CouponEdit: React.lazy(() =>
    awaitDelay(1000).then(() => import("../AdminSpace/pages/coupon/edit")),
  ),
  NotFound: React.lazy(() =>
    awaitDelay(1000).then(() => import("../Containers/NotFound/NotFound")),
  ),
}

export const BrowserRoutes = () => {
  const auth = useAuth()
  return (
    <Routes>
      {auth?.user.role !== "admin" && auth?.user.role !== "super_admin" ? (
        <Route path="" element={<AppLayout />}>
          <Route index element={<COMPONENTS.HomePage />} />
          <Route
            element={
              <ProtectedRoutes
                redirectPath={`/`}
                authorized={!auth?.user.token}
              />
            }
          >
            <Route path="/login" element={<COMPONENTS.Login />} />
            <Route path="/signup" element={<COMPONENTS.Signup />} />
            <Route
              path="/forgotPasword"
              element={<COMPONENTS.ForgotPassword />}
            />
            <Route
              path="/resetPasword/:id"
              element={<COMPONENTS.ResetPassword />}
            />
          </Route>

          <Route
            element={
              <ProtectedRoutes
                redirectPath={`/login`}
                authorized={auth?.user.token}
              />
            }
          >
            <Route path="/add-new-offer" element={<COMPONENTS.NewOffre />} />
            <Route path="/edit-offer" element={<COMPONENTS.EditOffer />} />
            <Route path="/manage-my-offers" element={<COMPONENTS.MyOffers />} />
            <Route
              path="/manage-my-bookings"
              element={<COMPONENTS.UserPage />}
            />
            <Route
              path="/booking-requests"
              element={<COMPONENTS.BookingRequest />}
            />
            <Route
              path="/profile-settings"
              element={<COMPONENTS.ProfileSetting />}
            />
            {<Route path="/analytics" element={<COMPONENTS.Statistics />} />}
            <Route
              path="/change-password"
              element={<COMPONENTS.ChangePassword />}
            />
            <Route path="/check-out/:id" element={<COMPONENTS.NewCheckOut />} />
            {<Route path="/user/:id" element={<COMPONENTS.ProfilePage />} />}
            <Route
              path="payment-process-success/:token"
              element={<COMPONENTS.PaymentSuccess />}
            />
            <Route
              path="payment-process-fail"
              element={<COMPONENTS.PaymentFail />}
            />
          </Route>

          <Route path="/Contact-Us" element={<COMPONENTS.ContactUs />} />
          <Route
            path="/Privacy-Policy"
            element={<COMPONENTS.PrivacyPolicy />}
          />
          <Route path="/terms-of-sales" element={<COMPONENTS.CGV />} />
          <Route
            path="/offer-details/:id"
            element={<COMPONENTS.OfferDetails />}
          />
          <Route
            path="*"
            element={<COMPONENTS.NotFound redirectPath={"/"} />}
          />
        </Route>
      ) : null}

      {/** admin basename */}
      {auth?.user.role !== "user" ? (
        <Route path="/admin" element={<AdminLayout />}>
          <Route
            element={
              <ProtectedRoutes
                redirectPath={"/admin/login"}
                authorized={auth?.user.token && auth?.user.role !== "user"}
              />
            }
          >
            <Route index element={<COMPONENTS.AdminHome />} />
            <Route
              path="/admin/manage-offers"
              element={<COMPONENTS.AdminOffersList />}
            />
            <Route
              path="/admin/manage-users"
              element={<COMPONENTS.UsersPage />}
            />
            <Route
              path="/admin/manage-bookings"
              element={<COMPONENTS.Bookings />}
            />
            {auth?.user.role === "super_admin" && (
              <>
                <Route
                  path="/admin/create-admin"
                  element={<COMPONENTS.AdminPage />}
                />
                <Route
                  path="/admin/manage-admins"
                  element={<COMPONENTS.AdminListPage />}
                />
              </>
            )}
            <Route
              path="/admin/registered-email"
              element={<COMPONENTS.MarketingPage />}
            />
            <Route
              path="/admin/change-password"
              element={<COMPONENTS.ChangePasswordAdmin />}
            />
            <Route path="/admin/coupon" element={<COMPONENTS.Coupon />} />
            <Route
              path="/admin/coupon/create"
              element={<COMPONENTS.CouponCreate />}
            />
            <Route
              path="/admin/coupon/edit/:id"
              element={<COMPONENTS.CouponEdit />}
            />
            <Route
              path="/admin/*"
              element={<COMPONENTS.NotFound redirectPath={"/admin"} />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                redirectPath={"/admin"}
                authorized={!auth?.user.token}
              />
            }
          >
            <Route path="/admin/login" element={<COMPONENTS.AdminLogin />} />
          </Route>
          <Route
            path="*"
            element={<COMPONENTS.NotFound redirectPath={"/admin"} />}
          />
        </Route>
      ) : null}
      {auth?.user?.role === "user" ? (
        <Route path="*" element={<COMPONENTS.NotFound redirectPath={"/"} />} />
      ) : (
        <Route
          path="*"
          element={<COMPONENTS.NotFound redirectPath={"/admin"} />}
        />
      )}
    </Routes>
  )
}
function awaitDelay(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay))
}
